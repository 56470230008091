<template>
  <div class="container">
    <div class="main">
      <div class="top-timer"></div>
      <div class="listmain">
        <div class="data">
          <h3>亚洲让分盘</h3>
          <ul class="nowrap_ul" v-for="i in 2">
            <li>
              <ol class="td_name w100">中国中国中</ol>

            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
          </ul>
        </div>
        <div class="data">
          <h3>大小盘</h3>
          <ul class="nowrap_ul" v-for="i in 2">
            <li>
              <ol class="td_name w100">{{i==1?'高于':'低于'}}</ol>

            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
          </ul>
        </div>
        <div class="data">
          <h3>上半场亚洲让分盘</h3>
          <ul class="nowrap_ul">
            <li>
              <ol class="td_name w100">中国中国中国中</ol>

            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li>
              <ol class="td_name w100">aodali打算</ol>
            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>

          </ul>
        </div>
        <div class="data">
          <h3>上半场大小盘</h3>
          <ul class="nowrap_ul">
            <li>
              <ol class="td_name w100">中国中国中国中</ol>

            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li>
              <ol class="td_name w100">aodali打算</ol>
            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>

          </ul>
        </div>
        <div class="data">
          <h3>全场赛果</h3>
          <ul class="nowrap_ul">

            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>

            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>

          </ul>
        </div>
        <div class="data">
          <h3>双胜彩</h3>
          <ul class="nowrap_ul">

            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>

            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>

          </ul>
        </div>
        <div class="data">
          <h3>半场结果</h3>
          <ul class="nowrap_ul">

            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>

            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>

          </ul>
        </div>
        <div class="data">
          <h3>第一进球</h3>
          <ul class="nowrap_ul">

            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>

            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>

          </ul>
        </div>
        <div class="data">
          <h3>比赛进球</h3>
          <ul class="nowrap_ul">
            <li>
              <ol class="td_name w100">高于</ol>

            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li>
              <ol class="td_name w100">低于</ol>
            </li>
            <li class="flex1">
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>

          </ul>
        </div>
        <div class="data">
          <h3>附加比赛进球数</h3>
          <ul class="nowrap_ul" v-for="i in 2">
            <li>
              <ol class="td_name w100">{{i==1?'高于':'低于'}}</ol>

            </li>
            <li>
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
          </ul>
        </div>
        <div class="data">
          <h3>上半场进球数</h3>
          <ul class="nowrap_ul" v-for="i in 2">
            <li>
              <ol class="td_name w100">{{i==1?'高于':'低于'}}</ol>

            </li>
            <li>
              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
            <li class="flex1">

              <ol class="td_wanfa">0-0-0-3</ol>
              <ol class="td_rate">2.3456</ol>
            </li>
          </ul>
        </div>
        <div class="data">
          <h3>比赛角球数</h3>
        </div>
        <div class="data">
          <h3>上半场角球数</h3>
        </div>
        <div class="data">
          <h3>下半场角球数</h3>
        </div>
        <div class="data">
          <h3>半场正确得分</h3>
          <ul class="nowrap_ul" v-for="i in 2">
            <li>
              <ol class="td_name w100">中国中国中</ol>

            </li>
            <li class="flex1" v-for="num in 10">
              <ol class="td_wanfa">{{num-1}}</ol>
              <!-- <ol class="td_rate">2.3456</ol> -->
            </li>

          </ul>
          <div class="data">
            <h3>球队得分</h3>
            <div class="flex_dantuo_li">
              <div class="dantuo-li">
                <span>中国:</span>
                <p v-for="lab in 8">[{{lab}}]</p>
              </div>
              <div class="dantuo-li">
                <span>日本:</span>
                <p v-for="lab in 9">[{{lab}}]</p>
              </div>
            </div>

            <div class="dantuo-li">
              <span>共 <em class="red">121</em>种组合 </span>
            </div>
            <div class="dantuo-sc  red">
              <span v-for="(item,index) in 123" :key="index">
                <span>[1，2]</span>

              </span>

            </div>
          </div>
        </div>
        <div class="data">
          <h3>全场比分</h3>
        </div>
        <div class="data">
          <h3>亚洲盘角球</h3>
        </div>
        <div class="data">
          <h3>上半场亚洲盘角球</h3>
        </div>
        <div class="data">
          <h3>角球比赛</h3>
        </div>

        <!-- ---------------- -->
      </div>
    </div>
    <div class="other">
      <div class="right-com">
        <div class="rc-tab">
          <span class="selected">右边头部</span>

        </div>
      </div>
    </div>
    <!-- <router-view></router-view> -->
  </div>
</template>

<script>
export default {
  name: "",
  props: [""],
  data() {
    return {};
  },
  components: {},
  created() {},
};
</script>
<style scoped>
.flex1 {
  flex: 1;
}
.w220 {
  min-width: 220px;
}
.listmain > .data > ul > li {
  width: 16.66%;
}
.listmain > .data > ul.fen2 > li {
  width: 50%;
}
.nowrap_ul li {
  justify-content: center;
}
.nowrap_ul {
  flex-flow: row nowrap;
}
.td_wanfa {
  width: 57px;

  justify-content: center;
}
.td_name.w100 {
  width: 100% !important;
}
</style>